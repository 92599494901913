import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import SubscribeForm from "../components/SubscribeForm";

export default function Page({ data }) {
  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <div className="head">
        <a href="/">
          <img
            alt="WeStack logo"
            src="https://westack.live/logo-180.png"
            width="100"
            height="100"
          />
        </a>
        <h1>{data.site.siteMetadata.title}</h1>
        <div style={{ marginBottom: 20 }}>
          每周推荐值得关注和有意思的技术工具
        </div>
      </div>
      <SubscribeForm />

      <div>
        {data.allMdx.edges.map(({ node }) => (
          <div key={node.id} className="card">
            <div className="card-head">
              <a href={node.slug} className="card-title">
                {node.frontmatter.title}
              </a>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/issues/" } }
      sort: { fields: slug, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
          }
          excerpt
        }
      }
    }
  }
`;
